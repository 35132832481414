// @flow
import React, { type Node } from "react";
import environment from "environment";
import {
  Box, Link, Typography,
} from "@mui/material";
import { makeStylesTyped } from "@fas/cpa-cabinet-ui/lib/helpers";
import { isAuthenticated } from "../../services/request";

const useStyles = makeStylesTyped((theme) => ({
  footer: {
    textAlign: "center",
    bottom: 0,
    zIndex: theme.zIndex.drawer + 1,
    position: isAuthenticated() ? "static" : "fixed",
    backgroundColor: theme.palette.background,
    width: "100%",
    padding: theme.spacing(1),
  },
  policy: {
    paddingRight: theme.spacing(1),
    color: theme.palette.policyLinks,
  },
  terms: {
    paddingLeft: theme.spacing(1),
    color: theme.palette.policyLinks,
  },
  copyright: {
    color: theme.palette.policyLinks,
  },
}));

function Footer(): Node {
  const classes = useStyles();
  return (
    <Box className={classes.footer}>
      <Typography className={classes.copyright} variant="body2">© 2021 by Detailed Graphics kft</Typography>
      <Box>
        <Link variant="body2" underline="none" className={classes.policy} target="_blank" href={environment.links.policy}>Privacy Policy</Link>
        <Link variant="body2" underline="none" className={classes.terms} target="_blank" href={environment.links.terms}>Terms of Use</Link>
      </Box>
    </Box>
  );
}
export default Footer;
